@import 'styles/utils/mixins';

.loadingPage {
  @include flex(column, center, center);

  width: 100vw;
  height: 100vh;
  text-align: center;
  position: fixed;
  z-index: 99999999999;
  opacity: 1;
  will-change: opacity;
  transition: opacity 0.6s ease-in;
  background-color: $white;
}

.hide {
  transition: opacity 0.6s ease-in;
  opacity: 0;
}

.setZIndex {
  z-index: -5;
}

.jsonWrapper {
  width: 50px;
  height: 50px;
}
